export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="candidates-list"]'
  );

  const initElements = async () => {
    const { default: CandidatesList } = await import(
      /* webpackChunkName: "candidates-list" */ './candidates-list'
    );
    elements.forEach((element) => new CandidatesList(element));
  };

  if (elements.length) initElements();
}

init();
