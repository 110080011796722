export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="person-cards"]');

  const initPersonCards = async () => {
    const { default: PersonCards } = await import(
      /* webpackChunkName: "person-cards" */ './person-cards'
    );
    elements.forEach((element) => new PersonCards(element));
  };

  if (elements.length) initPersonCards();
}

init();
