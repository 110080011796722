import forEach from '../../../javascripts/dom/forEach';

export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="gallery"]');
  if (elements) {
    const { default: Gallery } = await import(
      /* webpackChunkName: "gallery" */ './gallery'
    );

    forEach(elements, (index, element) => new Gallery(element));
  }
}
