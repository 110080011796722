export default async function init() {
  const elements = document.querySelectorAll('[data-js-module="main-stage"]');

  const initMainStage = async () => {
    const { default: MainStage } = await import(
      /* webpackChunkName: "main-stage" */ './main-stage'
    );
    elements.forEach((element) => new MainStage(element));
  };

  if (elements.length) initMainStage();
}

init();
