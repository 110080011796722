export default async function init() {
  const elements = document.querySelectorAll(
    '[data-js-module="intro-consultant"]'
  );

  const initIntroConsultant = async () => {
    const { default: IntroConsultant } = await import(
      /* webpackChunkName: "intro-consultant" */ './intro-consultant'
    );
    elements.forEach((element) => new IntroConsultant(element));
  };

  if (elements.length) initIntroConsultant();
}

init();
