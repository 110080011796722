import '../stylesheets/main.scss';

// Use what-input to track input method
import 'what-input';
import domready from 'domready';

// Shims
import forEach from './dom/forEach';

import initImage from '../components/image/javascripts';
import '../components/icon/javascripts';
import initFixAnchorLinks from './helpers/fixAnchorNavigation';

// import initTabs from '../components/tabs/javascripts';
import initAccordion from '../components/accordion/javascripts';
import initBlockMedia from '../components/block-media/javascripts';
import initCandidatesList from '../components/candidates-list/javascripts';
import initChart from '../components/chart/javascripts';
import initDebugGrid from '../components/debug-grid/javascripts';
import initDialog from '../components/dialog/javascripts';
import initFavoriteBtn from '../components/favorite-btn/javascripts';
import initFavoritesLink from '../components/favorites-link/javascripts';
import initFavoritesList from '../components/favorites-list/javascripts';
import initFileUpload from '../components/file-upload/javascripts';
import initForm from '../components/form/javascripts';
import initInfoPoint from '../components/info-point/javascripts';
import initInputGroup from '../components/input-group/javascripts';
import initInternalEvaluation from '../components/internal-evaluation/javascripts';
import initIntro from '../components/intro/javascripts';
import initIntroConsultant from '../components/intro-consultant/javascripts';
import initLinkList from '../components/link-list/javascripts';
import initList from '../components/list/javascripts';
import initLocation from '../components/location/javascripts';
import initLoop from '../components/loop/javascripts';
import initMainStage from '../components/main-stage/javascripts';
import initNavigation from '../components/navigation/javascripts';
import initNumbers from '../components/numbers/javascripts';
import initOrganisationAnalysis from '../templates/organisationAnalysis/javascripts';
import initPassword from '../components/password/javascripts';
import initQuestionaire from '../components/questionaire/javascripts';
import initRange from '../components/range/javascripts';
import initSearch from '../components/search/javascripts';
import initSortable from '../components/sortable/javascripts';
import initSteps from '../components/steps/javascripts';
import initSticky from '../components/sticky/javascripts';
import initSwitch from '../components/switch/javascripts';
import initTable from '../components/table/javascripts';
import initTabs from '../components/tabs/javascripts';
import initTeaserContainer from '../components/teaser-container/javascripts';
import initTeaserHero from '../components/teaser-hero/javascripts';
import initTeastimonials from '../components/testimonials/javascripts';
import initVideo from '../components/video/javascripts';
import initPersonCards from '../components/person-cards/javascripts';
import initGallery from '../components/gallery/javascripts';

function enhancedDomReady(init) {
  domready(init);
  window.addEventListener('reload', init);
}

enhancedDomReady(() => {
  // initTabs();
  initAccordion();
  initBlockMedia();
  initCandidatesList();
  initChart();
  initDebugGrid();
  initDialog();
  initFavoriteBtn();
  initFavoritesLink();
  initFavoritesList();
  initFileUpload();
  initFixAnchorLinks();
  initForm();
  initGallery();
  initImage();
  initInfoPoint();
  initInputGroup();
  initInternalEvaluation();
  initIntro();
  initIntroConsultant();
  initLinkList();
  initList();
  initLocation();
  initLoop();
  initMainStage();
  initNavigation();
  initNumbers();
  initOrganisationAnalysis();
  initPassword();
  initQuestionaire();
  initPersonCards();
  initRange();
  initSearch();
  initSortable();
  initSteps();
  initSticky();
  initSwitch();
  initTable();
  initTabs();
  initTeaserContainer();
  initTeaserHero();
  initTeastimonials();
  initVideo();
});

// All links with target="_blank" attribute should have an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
forEach(document.querySelectorAll("[target='_blank']"), (index, link) => {
  link.setAttribute('rel', 'noopener');
});
